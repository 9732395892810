<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>Amchara's Approach To Hormone Health</h1>
      </div>
    </div>
    <featured-in></featured-in>
    <!-- What is juice fasting section -->
    <div class="container mt-5">
      <div class="row text-center">
        <div class="col-md-9 mx-auto">
          <p>
            Hormone imbalances can affect men and women at any stage of life and
            can result in many challenging symptoms. At Amchara, we work with
            you to tailor a nutrition and supplement programme that facilitates
            detoxification, stimulates energy, aids sleep, rebalances your
            weight and ultimately aims to regain balance in all body systems.
          </p>
        </div>
      </div>
    </div>

    <div class="container pt-2 mb-4">
      <div class="row">
        <div class="col-md-12 text-center">
          <h3 style="color: #83c550">
            Are you experiencing any of these symptoms?
          </h3>
        </div>
      </div>

      <!-- Circle Slider -->
      <div class="container my-2 circle_slider" data-slider-id="1">
        <div class="row noselect">
          <div class="col-auto mx-auto">
            <!-- small green outer circle left -->
            <div
              class="green_circle outer_circle d-none d-lg-inline-block"
              data-position="outer_left"
            >
              <div class="green_circle_outer_left_text"></div>
              <div class="green_circle_outer_arrow"></div>
            </div>
            <!-- large green inner circle left -->
            <div
              class="green_circle inner_circle d-none d-lg-inline-block"
              data-position="inner_left"
            >
              <div class="green_circle_inner_left_text"></div>
              <div class="green_circle_inner_arrow"></div>
            </div>
            <div class="circle_slider_mobile_elements">
              <!-- left arrow on mobiles -->
              <div class="slider-arrow d-lg-none mx-3" data-rotate="left">
                <i class="fa fa-angle-left fa-4x" style="color: grey"></i>
              </div>
              <!-- large selected centre circle -->
              <div class="selected_circle selected_circle_orange">
                <div
                  class="selected_circle_text selected_circle_text_orange"
                ></div>
                <div
                  class="selected_circle_arrow selected_circle_arrow_orange"
                ></div>
              </div>
              <!-- right arrow on mobiles -->
              <div class="slider-arrow d-lg-none mx-3" data-rotate="right">
                <i class="fa fa-angle-right fa-4x" style="color: grey"></i>
              </div>
            </div>
            <!-- large green inner circle right -->
            <div
              class="green_circle inner_circle d-none d-lg-inline-block"
              data-position="inner_right"
            >
              <div class="green_circle_inner_right_text"></div>
              <div class="green_circle_inner_arrow"></div>
            </div>
            <!-- small green outer circle right -->
            <div
              class="green_circle outer_circle d-none d-lg-inline-block"
              data-position="outer_right"
            >
              <div class="green_circle_outer_right_text"></div>
              <div class="green_circle_outer_arrow"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-9 mx-auto">
            <!-- large selected centre circle subheader and description -->
            <div class="text-center">
              <div
                class="selected_circle_subheader selected_circle_subheader_orange my-3"
              ></div>
              <div class="selected_circle_description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-5 mb-4">
      <div class="row">
        <div class="col-md-9 mx-auto text-center">
          <h2 style="color: #006533">
            Many of these symptoms are caused by hormone imbalances
          </h2>
          <p>
            Your Amchara Personalised Health retreat experience can be designed
            with your personal hormone concerns and goals in mind. You will be
            immersed in a supportive and nurturing environment that enables you
            to switch off, relax, detox and kickstart your health journey.
          </p>
        </div>
      </div>
      <div class="row py-3 text-center">
        <div class="col-md-6 mx-auto">
          <router-link to="/special-offers" class="btn btn-orange-gradient"
            >SPECIAL OFFERS</router-link
          >
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <hr width="80%" />
        </div>
      </div>
    </div>

    <div class="container mt-4">
      <div class="row">
        <div class="col-12 mx-auto text-center">
          <h2 style="color: #006533" class="mt-3">What are hormones?</h2>
          <p>
            You may be unaware of the large extent to which hormones control
            you.
          </p>
          <p>
            There are over 100 identified hormones in the body and each one has
            a job to do. They essentially act as a communication system,
            dictating how we feel, think, eat, move, metabolise, digest, sleep
            and function.
          </p>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-4 mb-4">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="text-center">
              <h2 style="color: #006533">What your hormones do...</h2>
            </div>
            <div class="row">
              <div class="col-md-7 my-auto">
                <div class="row">
                  <div class="col-md-12">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-3 mb-md-0">
                        Regulate your heartbeat, breathing & blood pressure
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Influence your sleep/wake cycle
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Influence your appetite, metabolism & growth
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Influence your reproductive health & libido
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Support your bone health, repair skin & muscle
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Regulate your energy stores
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Influence your energy levels, mood & stress resilience
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Regulate brain activity - thinking, memory & focus
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Balance blood sugar levels & stimulate your immune
                        system
                      </li>
                      <li class="p-list-item mb-3 mb-md-0">
                        Help decrease inflammation
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-5 my-4 text-center">
                <img
                  width="800"
                  src="@/assets/images/pages/hormones/preview-full-Hormones collage Jan20-01.jpg"
                  alt="Personalised image"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <hr width="80%" />
        </div>
      </div>
    </div>

    <div class="container mt-4 mb-4">
      <div class="row">
        <div class="col-12 mx-auto text-center">
          <h2 style="color: #006533" class="mt-3">
            Restoring your hormonal balance
          </h2>
          <p>
            Restoring your hormonal balance is a whole body concept involving
            changes to your nutrition, lifestyle and environment.
          </p>
          <p>
            Our aim is to empower you to make ongoing and sustainable changes
            and access your body’s innate ability to heal, both on retreat and
            to your life going forward.
          </p>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <hr width="80%" />
        </div>
      </div>
    </div>

    <div class="container mt-4">
      <div class="row">
        <div class="col-12 mx-auto text-center">
          <h2 style="color: #006533" class="mt-3">
            How Amchara can help you balance your hormones
          </h2>
          <p>
            We will look at 6 key areas to help you balance your hormones.
            Everything we do will be tailored to your individual circumstances
            and your health goals.
          </p>
        </div>
      </div>
    </div>

    <div class="container py-4 show_hide" data-show-hide-id="1">
      <div class="row my-4">
        <div class="col-12 text-center">
          <div class="show-hide-arrow d-lg-none mx-3" data-rotate="left">
            <i class="fa fa-angle-left fa-4x" style="color: grey"></i>
          </div>

          <a class="hormones_keyareas selected">
            <div class="info_circle selected">
              <div class="text">Testing</div>
              <div class="arrow"></div>
            </div>

            <h4 style="display: none">
              Measuring your hormones - we don’t guess, we test!
            </h4>
            <span style="display: none">
              <p>
                Testing enables us to assess the key pillars of the hormone
                system - adrenals (stress), thyroid and sex hormones - to
                identify where any imbalances may lie and help you achieve
                optimal hormone health.
              </p>

              <p>
                Your Amchara thyroid test gives you a much more comprehensive
                understanding of your thyroid function than the typical tests
                offered by GPs. We use various sampling methods such as blood,
                urine and saliva to measure not only the levels of key hormones,
                but more importantly how your body is processing them.
                Understanding your DNA through genetic testing enables us to
                tailor an approach that works for you and your unique
                physiology.
              </p>

              <p>
                Once we have your results it helps us further personalise a
                tailored health experience and plan for you.
              </p>
            </span>
          </a>

          <a class="hormones_keyareas">
            <div class="info_circle">
              <div class="text">Nutrition & supplements</div>
              <div class="arrow"></div>
            </div>

            <h4 style="display: none">Nutrition & supplements</h4>
            <span style="display: none">
              <p>
                Many factors affect your body’s ability to effectively absorb
                nutrients and some people may tolerate and digest certain food
                more easily than others. On retreat, we will tailor your
                nutrition with nourishing healthful foods which will give you
                the best health retreat experience for the short-term and the
                best long-term health outcomes.
              </p>

              <p>
                We review your current lifestyle and nutrition choices as well
                as look at introducing supplements to support any deficiencies
                or absorption issues. We will create a Personalised Health plan
                that works for you.
              </p>
            </span>
          </a>

          <a class="hormones_keyareas">
            <div class="info_circle">
              <div class="text">Detoxification</div>
              <div class="arrow"></div>
            </div>

            <h4 style="display: none">Detoxification</h4>
            <span style="display: none">
              <p>
                Poor nutrition and unhealthy lifestyle choices can lead to
                nutrient deficiencies and can overburden the liver, causing
                toxins to accumulate.
              </p>

              <p>
                Our team of Personalised Health practitioners will give you
                support to help you detoxify and encourage your body to cleanse
                and heal.
              </p>

              <p>
                We also offer long-term support through online consultations
                post-retreat to help you on your journey to optimal health.
              </p>
            </span>
          </a>

          <a class="hormones_keyareas">
            <div class="info_circle">
              <div class="text">Health treatments</div>
              <div class="arrow"></div>
            </div>

            <h4 style="display: none">Health treatments</h4>
            <span style="display: none">
              <p>
                We offer treatments to help restore hormone balance, including
                lymphatic drainage massage, colonic hydrotherapy and
                reflexology, as well as a range of exercise and wellbeing
                classes, including yoga, meditation and breathwork.
              </p>
            </span>
          </a>

          <a class="hormones_keyareas">
            <div class="info_circle">
              <div class="text">Lifestyle and environment</div>
              <div class="arrow"></div>
            </div>

            <h4 style="display: none">Lifestyle and environment</h4>
            <span style="display: none">
              <p>
                Prolonged stress, lack of sleep, a sedentary lifestyle and
                exposure to environmental toxins can all lead to a disruption of
                your hormone levels.
              </p>
              <p>
                We will help you on your journey to rebalance your hormones by
                creating a Personalised Health plan to provide you with
                strategies to facilitate restful sleep, manage your stress
                levels and make positive lifestyle changes.
              </p>
            </span>
          </a>

          <a class="hormones_keyareas">
            <div class="info_circle">
              <div class="text">Genetics & epigenetics</div>
              <div class="arrow"></div>
            </div>

            <h4 style="display: none">Genetics & epigenetics</h4>
            <span style="display: none">
              <p>
                Understanding your genetic make-up can enable us to look at your
                susceptibility to certain hormone-related conditions.
                Epigenetics can turn your genes on or off, affecting hormone
                balance, and this can be affected by aspects of your
                environment, nutrition and lifestyle.
              </p>

              <p>
                Our approach is to explore your genetic make-up through tests
                and investigate factors that may be influencing how your genes
                are expressed, in order to optimise your wellness journey.
              </p>
            </span>
          </a>

          <div class="show-hide-arrow d-lg-none mx-3" data-rotate="right">
            <i class="fa fa-angle-right fa-4x" style="color: grey"></i>
          </div>
        </div>
      </div>

      <div class="row my-4 text-center">
        <div class="col-xs-12 col-md-9 mx-auto">
          <h2 style="color: #009fe3" class="mt-3 hormones_keyareas_title">
            Measuring your hormones - we don’t guess, we test!
          </h2>
          <div class="hormones_keyareas_content">
            <p>
              Testing enables us to assess the key pillars of the hormone system
              - adrenals (stress), thyroid and sex hormones - to identify where
              any imbalances may lie and help you achieve optimal hormone
              health.
            </p>

            <p>
              Your Amchara thyroid test gives you a much more comprehensive
              understanding of your thyroid function than the typical tests
              offered by GPs. We use various sampling methods such as blood,
              urine and saliva to measure not only the levels of key hormones,
              but more importantly how your body is processing them.
              Understanding your DNA through genetic testing enables us to
              tailor an approach that works for you and your unique physiology.
            </p>

            <p>
              Once we have your results it helps us further personalise a
              tailored health experience and plan for you.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-4">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-9 mx-auto text-center">
            <h2 style="color: #83c550" class="mt-3">
              We are driven to support and empower you to ‘<strong
                >Change for Good</strong
              >’.
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-4">
      <div class="container">
        <div class="row">
          <hr width="80%" />
        </div>
      </div>
    </div>

    <div class="container-fluid mt-4 mb-5">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-9 mx-auto text-center">
            <h2 style="color: #006533" class="mt-3">The Next Steps</h2>
            <p>
              Get in touch to find out how Amchara can help you on your journey
              to optimal hormone health.
            </p>

            <p>
              Complete the form below to make an enquiry and our team will give
              you a call.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container">
        <enquiry-form
          :source="'web_form'"
          :traffic_campaign="traffic_campaign"
          :traffic_source="traffic_source"
        ></enquiry-form>

        <div class="container mt-5">
          <div class="row">
            <hr width="80%" />
          </div>
        </div>

        <trip-advisor></trip-advisor>

        <div class="container mt-5">
          <div class="row">
            <hr width="80%" />
          </div>
        </div>

        <featured-mags></featured-mags>

        <testimonials></testimonials>
      </div>
    </div>

    <!-- Newsletter -->
    <div>
      <newsletter-cta></newsletter-cta>
    </div>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import TripAdvisor from "./page-partials/TripAdvisor";
import FeaturedMags from "./page-partials/FeaturedMags";
import Testimonials from "./page-partials/Testimonials";
import NewsletterCta from "./page-partials/NewsletterCta";
import BenefitsOfJuiceFasting from "./page-partials/BenefitsOfJuiceFasting";
import EnquiryForm from "./page-partials/EnquiryForm";
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [
        require("@/assets/images/pages/hormones/hormones_header_1.jpg"),
      ],
      traffic_campaign: sessionStorage.getItem('traffic_campaign'),
      traffic_source: sessionStorage.getItem('traffic_source'),
    };
  },
  mounted() {
    var sliders = {
      slider_1: {
        outer_left: {
          title: "Weight management",
          subheader: "",
          description:
            "Changes in hormone levels can influence your weight. Rebalancing your hormones through positive nutrition and lifestyle choices can help you maintain a healthy body weight.",
        },
        inner_left: {
          title: "Increased stress",
          subheader: "",
          description:
            "Your stress hormones are finely tuned to allow your body to cope with day-to-day stress. But prolonged, chronic stress can cause your body to be in a constant state of  ‘fight, flight or freeze’ mode, due to high levels of the stress hormone, cortisol. If you are suffering from chronic stress  your body may not be able to ‘rest and digest’ adequately, contributing to a number of health issues.",
        },
        middle: {
          title: "Fatigue & exhaustion",
          subheader: "",
          description:
            "The feeling of chronic exhaustion and a total lack of energy is a common symptom of hormone imbalance. ",
        },
        inner_right: {
          title: "Emotional health",
          subheader: "",
          description:
            "A shift in your hormone balance can influence your emotional health. You may find you increasingly struggle with mood swings, low mood, depression, irritability, anger, panic and anxiety.",
        },
        outer_right: {
          title: "Brain fog",
          subheader: "",
          description:
            "Brain fog affects mental processes such as memory and concentration. A change in your hormone levels can affect cognitive function and rebalancing your hormones can help to restore mental clarity.",
        },
      },
    };

    $(document).ready(function () {
      // on page load
      $(".circle_slider").each(function () {
        var sliderID = $(this).data("slider-id");

        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);
        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });

      // desktop event listener
      $(".green_circle").click(function () {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var position = $(this).data("position");

        // switch the newly selected object with the previously selected object
        var previously_selected_obj = sliders["slider_" + sliderID]["middle"];
        var newly_selected_obj = sliders["slider_" + sliderID][position];
        sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        sliders["slider_" + sliderID][position] = previously_selected_obj;

        // now update the objects on screen
        $(this)
          .find(".green_circle_" + position + "_text")
          .html(previously_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(newly_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(newly_selected_obj.subheader);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(newly_selected_obj.description);
      });

      // mobile event listener
      $(".slider-arrow").click(function () {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var sliderRotate = $(this).data("rotate");

        // move all objects up or down one depending on whether left (down) or right (up) arrow was pressed
        if (sliderRotate == "left") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_left"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["inner_right"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["inner_right"];
          }
          sliders["slider_" + sliderID]["inner_right"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        } else if (sliderRotate == "right") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_right"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["inner_left"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["inner_left"];
          }
          sliders["slider_" + sliderID]["inner_left"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        }

        // now update the objects on screen
        // including the (hidden on mobile) green circles incase
        // they are on desktop and expand the vieport
        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this_circle_slider)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this_circle_slider)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);
        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });

      $(".hormones_keyareas").on("click", function () {
        var title = $(this).find("h4").html();
        var content = $(this).find("span").html();

        $(".hormones_keyareas").each(function () {
          $(this).removeClass("selected");
        });

        $(this).addClass("selected");

        $(".info_circle").each(function () {
          $(this).removeClass("selected");
        });

        $(this).find("div.info_circle").addClass("selected");

        $(".hormones_keyareas_title").html(title);
        $(".hormones_keyareas_content").html(content);
      });

      $(".show-hide-arrow").click(function () {
        var this_show_hide = $(this).closest(".show_hide");
        var showHideID = this_show_hide.data("show-hide-id");
        var showHideRotate = $(this).data("rotate");

        // move all objects up or down one depending on whether left (down) or right (up) arrow was pressed
        if (showHideRotate == "left") {
          $(".hormones_keyareas.selected").prev().trigger("click");
        }

        if (showHideRotate == "right") {
          $(".hormones_keyareas.selected").next().trigger("click");
        }
      });

      $(".uk_thumb").on("click", function () {
        var thumb = $(this).find("img").attr("src");
        $(".main_uk_image").attr("src", thumb);
      });

      $(".malta_thumb").on("click", function () {
        var thumb = $(this).find("img").attr("src");
        $(".main_malta_image").attr("src", thumb);
      });
    });
  },
  components: {
    FeaturedIn,
    FeaturedMags,
    TripAdvisor,
    Testimonials,
    NewsletterCta,
    BenefitsOfJuiceFasting,
    EnquiryForm,
  },
};
</script>
